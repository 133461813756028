/* @flow */

import Bourne from '@hapi/bourne';

import { reportError } from './helpers';

/**
 * A list if keys to ignore when parsing.
 * @type {string[]}
 */
const blacklist = [ '__proto__', 'constructor', 'prototype' ];

/**
 * Parses the query/search or fragment/hash parameters out of a specific URL and
 * returns them as a JS object.
 *
 * @param {URL} url - The URL to parse.
 * @param {boolean} dontParse - If falsy, some transformations (for parsing the
 * value as JSON) will be executed.
 * @param {string} source - If {@code 'search'}, the parameters will parsed out
 * of {@code url.search}; otherwise, out of {@code url.hash}.
 * @returns {Object}
 */
var url_session_param,iframe_session_param;
export function parseURLParams(
        url: URL,
        dontParse: boolean = false,
        source: string = 'hash'): Object {
    const paramStr = source === 'search' ? url.search : url.hash;
    const params = {};
    const paramParts = (paramStr && paramStr.substr(1).split('&')) || [];
    window.iframe_session_param = paramStr;
    // Detect and ignore hash params for hash routers.
    if (source === 'hash' && paramParts.length === 1) {
        const firstParam = paramParts[0];

        if (firstParam.startsWith('/') && firstParam.split('&').length === 1) {
            return params;
        }
    }

    var param_json = {};
    paramParts.forEach(part => {
        const param = part.split('=');
        const key = param[0];
        param_json[key] = param[1];
        window.url_session_param = param_json;

        if (!key || key.split('.').some(k => blacklist.includes(k))) {
            return;
        }

        let value;

        try {
            value = param[1];

            if (!dontParse) {
                // Prospatium ---
                var decoded = decodeURIComponent(value).replace(/\\&/, '&');
                if( param[0] === 'userInfo.displayName' ||
                    param[0] === 'userInfo.email'||
                    param[0].indexOf('cb_') === 0 ||
                    param[0] === 'hostKey' ||
                    param[0] === 'resolution' ||
                    param[0] === 'disableToolbarButtons[]' ||
                    param[0] === 'agreement'){

                    if(decoded.charAt(0) !== '"') {
                        decoded = '\"' + decoded;
                    }
                    if(decoded.charAt(decoded.length-1) !== '"'){
                        decoded = decoded + '\"';
                    }
                }

                if(String(decoded) == "\""){
                    decoded="";
                }
                if(decoded.length === 0){
                    decoded = '\"\"';
                }

                if(param[0] === 'userInfo.description' || param[0] === 'hostKey'){
                    value = decoded;
                } else{
                    value = decoded === 'undefined' ? undefined : Bourne.parse(decoded);
                }
                // Prospatium ---
            }
        } catch (e) {
            reportError(
                e, `Failed to parse URL parameter value: ${String(value)}`);

            return;
        }
        params[key] = value;
    });

    return params;
}
